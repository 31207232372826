import React from "react"
const Article = (props) => {
  return (
    <>
      <p>{props.title}</p>
      <p>{props.hello}</p>
    </>
  );
};

export default Article;
