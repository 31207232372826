const Rails = require('@rails/ujs')
import '../components/front/application';

// 初期化処理
import Initializer from 'lib/initializer'

Initializer.loadWebfont();
Initializer.analytics();
//Initializer.turbolinksDebug();

// turbolinksでのリクエスト時に発動
document.addEventListener('turbolinks:load', () => {

  Initializer.activationNavigation();
  Initializer.lazy();
  Initializer.smoothScroll();

  // instagram埋め込みturbolink対策
  if (window.instgrm) window.instgrm.Embeds.process();
});
