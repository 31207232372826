import React from "react"
import Article from "./article"

function Top() {
  return (
    <>
      <Article
        title={'こんにちは'}
        hello={'挨拶'}
      />
    </>
  );
}

export default Top;